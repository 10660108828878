import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import getAxiosInstance from '../../Utils/axios';
import Swal from 'sweetalert2';



const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
function ScholarShipTestModel({data}) {

  console.log(data)
  const CloseButton = useRef();
  // /api/v1/scholarship/quiz/:categoryId

  const initialLocalData = {
    // scholarship_category_name: data?.scholarship_category_name || "",
    
      scholarship_quiz_name: data?.scholarship_quiz_name || "",
      scholarship_quiz_description: data?.scholarship_quiz_description || "",
      scholarship_quiz_terms_and_condition: data?.scholarship_quiz_terms_and_condition || "",
      show_questions_randomly: data?.show_questions_randomly || false,
      can_switch_sections:data?.can_switch_sections || false,
      scholarship_quiz_duration: data?.scholarship_quiz_duration || null, 
      break_between_sections:data?.break_between_sections || null,
      max_attempts:data?.max_attempts || null,
    
  };
  const {scholarshipquizId} = useParams("")


const [localData,setLocaldata] =useState(initialLocalData)

async function handleAddQuiz(e){
  e.preventDefault()
        try {
        //   setIsLoading(true);
          const res = await getAxiosInstance().post(`/api/v1/scholarship/quiz/${scholarshipquizId}`, localData);
          console.log(res)
          Toast.fire({
            icon: 'success',
            title: res.data.message || '"Quiz created successfully"',
          });
        //   handleImageUpload(res.data.category._id);
        //   setLocalData(initialLocalData);
        CloseButton.current.click();
        // fetchScholarshipData();
        window.location.reload();
        //   setTimeout(function () {
        //   }, 500);
        //   setIsLoading(false);
        setLocaldata(initialLocalData)
        } catch (error) {
          console.log(error);
        //   Toast.fire({
        //     icon: 'error',
        //     title: error.response.data ? error.response.data.msg : error.message,
        //   });
        //   setIsLoading(false);
        }



     
}
async function handleUpdateQuiz(e){
  e.preventDefault()
        try {
        //   setIsLoading(true);
          const res = await getAxiosInstance().patch(`/api/v1/scholarship/quiz/${data._id}`, localData);
          console.log(res)
          Toast.fire({
            icon: 'success',
            title: res.data.message || '"Quiz created successfully"',
          });
        //   handleImageUpload(res.data.category._id);
        //   setLocalData(initialLocalData);
        CloseButton.current.click();
        // fetchScholarshipData();
        window.location.reload();
        //   setTimeout(function () {
        //   }, 500);
        //   setIsLoading(false);
        setLocaldata(initialLocalData)
        } catch (error) {
          console.log(error);
        //   Toast.fire({
        //     icon: 'error',
        //     title: error.response.data ? error.response.data.msg : error.message,
        //   });
        //   setIsLoading(false);
        }



     
}
async function handleDeleteQuiz(e){
  e.preventDefault()
        try {
        //   setIsLoading(true);
          const res = await getAxiosInstance().delete(`/api/v1/scholarship/quiz/${data._id}`);
          console.log(res)
          Toast.fire({
            icon: 'success',
            title: res.data.message || '"Quiz deleted successfully"',
          });
        //   handleImageUpload(res.data.category._id);
        //   setLocalData(initialLocalData);
        CloseButton.current.click();
        // fetchScholarshipData();
        window.location.reload();
  
        } catch (error) {
          console.log(error);
        //   Toast.fire({
        //     icon: 'error',
        //     title: error.response.data ? error.response.data.msg : error.message,
        //   });
        //   setIsLoading(false);
        }



     
}


  console.log(localData)


    function scholarshipinp(e){
      const { name, value } = e.target;
      console.log(e)
      setLocaldata(prevState => ({
        ...prevState,
        [name]: name === "scholarship_quiz_duration" || name === "max_attempts" || name === "break_between_sections" ? parseInt(value): name === "can_switch_sections" || name === "show_questions_randomly" ? value === "true" : value
      }));

  }
  return (
    <>
    <button
      type="button"
      className="btn btn-dark ms-2 d-flex align-items-center"
      data-toggle="modal"
      // id={data ? `#open${data._id}` : '#open-add-quiz-modal'}
      data-target={data ? `#${data._id}` : '#add-quiz-modal'}
    >
      {data ? (
        <i className="fa fa-cog" aria-hidden="true" />
      ) : (
        <>
          <i className="fa fa-plus me-1" aria-hidden="true" /> Quiz
        </>
      )}
    </button>
    <div
      className="modal fade"
      id={data ? data._id : 'add-quiz-modal'}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <form onSubmit={handleAddQuiz}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {data ? <>Manage Test</> : <>Add Quiz</>}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={CloseButton}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            
            <label htmlFor="quiz_name" className="form-label mt-2">
              Quiz Name
            </label>
            <input
              type="text"
              required
              className="form-control"
              id="quiz_name"
              name='scholarship_quiz_name'
              value={localData.scholarship_quiz_name}
              onChange={scholarshipinp}
              
            />
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="quiz_description" className="form-label mt-2">
                  Quiz Description
                </label>
                <textarea
                  type="text"
                  required
                  value={localData.scholarship_quiz_description}
                  className="form-control"
                  id="quiz_description"
                  name='scholarship_quiz_description'
                  onChange={scholarshipinp}
                 
                />
              </div>
              <div className="col-12 col-md-6">
                <label
                  htmlFor="quiz_terms_and_condition"
                  className="form-label mt-2"
                >
                  Quiz Terms and Condition
                </label>
                <textarea
                  type="text"
                  required
                  className="form-control"
                  id="quiz_terms_and_condition"
                  name='scholarship_quiz_terms_and_condition'
                  value={localData.scholarship_quiz_terms_and_condition}
                  onChange={scholarshipinp}
                  
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="quiz_duration" className="form-label mt-2">
                  Quiz Duration (in min)
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  id="quiz_duration"
                  name='scholarship_quiz_duration'
                  value={localData.scholarship_quiz_duration}
                  onChange={scholarshipinp}
                  
                />
              </div>
              <div className="col-12 col-md-6">
                <label
                  htmlFor="break_between_sections"
                  className="form-label mt-2"
                >
                  Break b/w sections (in min)
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  id="break_between_sections"
                  name='break_between_sections'
                  value={localData.break_between_sections}
                  onChange={scholarshipinp}
                  
                />
              </div>
            </div>
            <label htmlFor="max_attempts" className="form-label mt-2">
              Max Attempt
            </label>
            <input
              type="number"
              required
              className="form-control"
              id="quiz_name max_attempts"
              name='max_attempts'
              value={localData.max_attempts}
              onChange={scholarshipinp}
              
            />
            <div className="row">
              {/* <div className="col-12 col-md-6">
                <label htmlFor="quiz_is_free" className="form-label mt-2">
                  Is Paid / Free
                </label>
                <select
                  className="form-select w-100"
                  id="quiz_is_free"
                  style={{
                    background: '#ff959e'
                  }}
                  
                >
                  <option value="false">Paid</option>
                  <option value="true">Free</option>
                </select>
              </div> */}
              <div className="col-12 col-md-12">
                {' '}
                <label
                  htmlFor="show_questions_randomly"
                  className="form-label mt-2"
                >
                  Show question randomly
                </label>
                <select
                  className="form-select w-100"
                  required
                  id="show_questions_randomly"
                  name='show_questions_randomly'
                  onChange={scholarshipinp}
                  style={{
                    background: '#ff959e'
                  }}
                  value={localData.show_questions_randomly}
                  
                >
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="can_switch_sections"
                className="form-label mt-2"
              >
                User can Switch Sections
              </label>
              <select
                className="form-select w-100"
                required
                id="can_switch_sections"
                style={{
                  background: '#ff959e'
                }}
                value={localData.can_switch_sections}
                name='can_switch_sections'
                onChange={scholarshipinp}
                
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
              
              <div className="form-text" id="basic-addon4">
                If 'User can Switch Sections' = True, User can switch b/w
                multiple sections and 'Quiz Duration' is used otherwise User
                cannot switch b/w multiple sections and 'Section Duration' is
                used
              </div>
              
            </div>
          </div>

          <div className="modal-footer">
            {data ? (
              <>
                {/* Manage */}
                <button
                  type="button"
                  className="btn btn-danger me-auto"
                  onClick={handleDeleteQuiz}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleUpdateQuiz}
                >
                  Save changes
                </button>
              </>
            ) : (
              <>
                {/* Add New */}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // onClick={handleAddQuiz}
                >
                  Add
                </button>
              </>
            )}
          </div>
        </div>
        </form>
      </div>
    </div>
  </>
  )
}

export default ScholarShipTestModel