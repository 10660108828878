import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import xlsxParser from 'xlsx-parse-json';
import ExcelJS from 'exceljs'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const AddQueOptionImg = () => {
  const CloseButton = useRef();
  const { setIsLoading } = useStore();
  const [bannerData, setBannerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [imageGallery, setImageGallery] = useState([]);
  const [ImageAlldelete, setImageAlldelete] = useState(0);

  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('banner_title');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPage = 20;

  // getting banner data from database
  const fetchBannerData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/uploads/images`);
      // setBannerData(response.data.data);
      setImageGallery(response.data?.data[0]?.image_url || []);
      console.log(response.data?.data)
      setImageAlldelete(response.data?.data[0]?._id || 0)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title:  error.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBannerData();
 
  }, [setIsLoading]);


  const handleDeleteBanner = async (url) => {
    console.log(url)
    let Imagedelete = {
      imageUrl: url,
    }
    console.log(Imagedelete)
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h6>This image will get permanently deleted</h6>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#D14343',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          await axios().delete(`/api/v1/uploads/imagesDelete`,
          {
            data:Imagedelete
          });
          Toast.fire({
            icon: 'success',
            title: 'Image deleted',
          });
          setTimeout(function () {
            fetchBannerData();
          }, 500);
          // CloseButton.current.click();
          setIsLoading(false);
        } catch(error) {
          console.log(error);
          Toast.fire({
            icon: 'error',
            title:  error.message,
          });
          setIsLoading(false);
        }
      }
    });
  };



    // State to keep track of copied image URL
    const [copiedImageUrl, setCopiedImageUrl] = useState(false);

    // Function to copy URL to clipboard
    const copyToClipboard = (imageUrl) => {
      navigator.clipboard.writeText(imageUrl);
      setCopiedImageUrl(true);
      setTimeout(() => {
        setCopiedImageUrl(null);
      }, 1000);
      // Toast.fire({
      //   icon: 'success',
      //   title: "Copied!",
      // })
    };



    


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
           
              {copiedImageUrl && <h5 className='position-fixed top-25 start-50 z-3 text-success '><i className="fa fa-check me-1" aria-hidden="true" />Copied!</h5>}
            
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-television me-2" />
                  Add Image
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  {/* <li className="breadcrumb-item">App</li> */}
                  <li className="breadcrumb-item ">add-image</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5 ">
              <div className="card-header d-flex">
                {/* <input

                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for banner title"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                /> */}
                <ManageBannerModal imageGallery={imageGallery} ImageAlldelete={ImageAlldelete} fetchBannerData={fetchBannerData} />
              </div>
              <div className="card-body" style={{ overflow: 'auto' }}>
              
                {/* <div class="container"> */}
                  <ul class="galleryUL ">
                    {imageGallery.length ?
                      imageGallery.map((img, i) => {
                        const splitUrl = img?.split('images/');
                        const imageName = splitUrl[1]?.split('.')[0];
                        return (
                          <li id='Image_div' className=''>
                        
                            <figure>
                              <img className=" image_sec" src={img} alt={imageName} />
                              <figcaption>
                              <div className='Iamge_delete_copy_button'>
                              <h5>{imageName}</h5>
                              <button onClick={()=>copyToClipboard(img)}
                                className="btn btn-info rounded-2 mr-3"
                              >
                                <i
                                  className={copiedImageUrl ?"fa fa-check ms-1": "fa fa-copy ms-1"}
                                  aria-hidden="true"
                                />
                              </button>
                              <button onClick={()=>handleDeleteBanner(img)}
                                className="btn btn-danger rounded-2"
                              >
                                <i className="fa fa-trash" aria-hidden="true" />
                              </button>
                            
                            </div>
                              </figcaption>
                            </figure>
                         
                        </li>
                        )
                      })
                      :
                      <div className=''>
                        <h5 className='text-center '>No Image Found</h5>
                        
                      </div>
                    }
                  </ul>
                {/* </div> */}
              </div>
              <div className="card-footer clearfix d-flex justify-content-center ">
               
              </div>
            {/* {copiedImageUrl === true && <h5 className='fixed-bottom '>Copied!</h5>} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQueOptionImg;

const TableContent = ({
  fetchBannerData,
  paginatedData,
  currentPage,
  rowsPerPage,
}) => {
  return (
    <>
      {paginatedData.length === 0 ? (
        <tr>
          <td colSpan="8" className="text-center">
            No data
          </td>
        </tr>
      ) : (
        paginatedData.map((data, index) => {
          return (
            <tr key={data._id}>
              <th scope="row">
                {currentPage * rowsPerPage - rowsPerPage + index + 1}
              </th>

              <td className='d-flex gap-3 align-items-center '>
                <div>
                  <img src={data.image_url} alt='image' width={100} height={100} ></img>
                </div>
                <div className=''>
                  <div className='' dangerouslySetInnerHTML={{
                    __html: data.title?.replaceAll('&lt;', '<'),
                  }}>

                  </div>
                  <div className='' dangerouslySetInnerHTML={{
                    __html: data.description?.replaceAll('&lt;', '<'),
                  }}>

                  </div>
                </div>

              </td>

              <td>
                <div className='d-flex justify-content-end '>
                  <ManageBannerModal
                    data={data}
                    fetchBannerData={fetchBannerData}
                  />
                </div>
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};

const ManageBannerModal = ({imageGallery,ImageAlldelete, data, fetchBannerData }) => {
  const CloseButton = useRef();
  const [imageData, setImageData] = useState([]);
  const { setIsLoading } = useStore();
  

  const transformedArray = imageGallery.map((url, index) => {
    const splitUrl = url.split('images/');
    const imageName = splitUrl[1]?.split('.')[0];
  
    return {
      S_NO: index + 1,
      IMAGE_URL: url,
      TITLE: imageName || `Image ${index + 1}` // If imageName is not available, fallback to default title
    };
  });
  console.log(transformedArray)


  const handleAddBanner = async () => {
    const formData = new FormData()
    formData.append('image_url', imageData);
    for (let i = 0; i < imageData.length; i++) {
      formData.append(`image_url`, imageData[i]);
    }

    console.log(imageData)

    try {
      setIsLoading(true);
      const res = await axios().post(`/api/v1/uploads/images`, formData);
      Toast.fire({
        icon: 'success',
        title: res?.data?.message || 'Images added successfully',
      });
      setTimeout(function () {
        fetchBannerData();
      }, 500);
      CloseButton.current.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.message,
      });
      setIsLoading(false);
    }
  };

  const handleDeleteImgAll = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h6>All image will get permanently deleted</h6>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#D14343',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
         let res =  await axios().delete(`/api/v1/uploads/imagesDelete/${ImageAlldelete}`);
         console.log(res)
          Toast.fire({
            icon: 'success',
            title: res?.data?.message || "All Images deleted successfully",
          });
          setTimeout(function () {
            fetchBannerData();
          }, 500);
          // CloseButton.current.click();
          setIsLoading(false);
        } catch(error) {
          console.log(error);
          Toast.fire({
            icon: 'error',
            title:  error.message,
          });
          setIsLoading(false);
        }
      }
    });
  };


  // function handleDownloadCSV(){
  //     const items = transformedArray;
  
  //     const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  //     // const header = Object.keys(items[0])
  //     let header = [
  //       'S_NO',
  //       'TITLE',
  //       'IMAGE_URL',
     
  //     ];
  //     const csv = [
  //       header.join(','), // header row first
  //       ...items.map((row) =>
  //         header.map((f)=>JSON.stringify(row[f],replacer))
  //         .join(',')
  //           // .map((fieldName) => JSON.stringify(row, replacer))
  //       ),
  //     ].join('\r\n');
  
  //     // Create link and download
  //     var link = document.createElement('a');
  //     // link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  //     link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURI(csv));
  
  //     link.setAttribute('download', `Image Links`);
  //     link.style.visibility = 'hidden';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
    
    
  // }



  async function handleDownloadCSV(){
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
  
    sheet.getRow(1).height = 21;
  
  sheet.getRow(1).border = {
    top: { style: "thin", color: { argb: "FF000000" } },
    left: { style: "thin", color: { argb: "FF000000" } },
    bottom: { style: "thin", color: { argb:"FF000000" } },
    right: { style: "thin", color: { argb: "FF000000" } },

  };
  
  sheet.getRow(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "775da8" },
    

  };
  sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  
  sheet.getRow(1).font = {
    size: 12,
    bold: true,
    color: { argb: 'ffffffff' },
  };
  // Define columns
  sheet.columns = [
    { header: "S NO.", key: "S_NO", width: 17 },
    { header: "TITLE", key: "TITLE", width: 19 },
    { header: "IMAGE URL", key: "IMAGE_URL", width: 60 },
    
  ];
  
  // Add rows
  transformedArray.forEach(imgURL => {
    
    
    sheet.addRow({
      S_NO: imgURL?.S_NO,
      TITLE: imgURL?.TITLE,
      IMAGE_URL: imgURL?.IMAGE_URL,
    });
  });
  sheet.getColumn("S_NO").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  sheet.getColumn("IMAGE_URL").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
 

  
  
  //   sheet.eachRow(row => {
  //   row.eachCell(cell => {
  //     cell.font = { bold: true ,vertical:"middle", horizontal: 'center'};
  //   });
  // });

    try {
      // Write to buffer and create download link
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Navya Image.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error creating Excel file:', error);
    }
  }

  return (
    <>
      
      <div className='d-flex justify-content-end w-100'>
      
      
      <button
        type="button"
        className="btn btn-dark ms-2 d-flex align-items-center"
        data-toggle="modal"
        data-target={'#add-banner-modal'}
      >
       
          <>
            <i className="fa fa-plus me-1" aria-hidden="true" /> Add
          </>
      
      </button>
      <button
      onClick={handleDownloadCSV}
      style={{backgroundColor:"#775da8",color:"#fff"}}
        type="button"
        className={!imageGallery.length ? "d-none " :"btn ms-2 d-flex align-items-center "}>
      <i className="fa fa-download me-1" aria-hidden="true" /> 
      </button>
      <button
      onClick={handleDeleteImgAll}
        type="button"
        className={!imageGallery.length ? "d-none " :"btn btn-danger ms-2 d-flex align-items-center "}>
      <i className="fa fa-trash me-1" aria-hidden="true" /> All
      </button>
      
      </div>
      <div
        className="modal fade"
        id={data ? data._id : 'add-banner-modal'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={handleAddBanner}>
          <div className="modal-dialog" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <>Add Image</>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={CloseButton}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label htmlFor="banner_image" className="form-label mt-2">
                  Add Image
                </label>
                <div className="d-flex gap-2">
                  
                  <input
                    type="file"
                    required
                    id="banner_image"
                    accept="image/*"
                    className="form-control"
                    multiple
                    onChange={(e) => setImageData(e.target.files)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                    {/* Add New */}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                    // onClick={handleAddBanner}
                    >
                      Add
                    </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
