import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { JsDateToString } from '../../Utils/dateEditor';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const initialData = {
  email: '',
  country_code: 0,
  phone_no: 0,
  address: '',
  address_lat: '',
  address_long: '',
};

const ContactUs = () => {
  const { setIsLoading } = useStore();
  const [contactUsData, setContactUsData] = useState([]);

  // getting contact us data from database
  const fetchContactUsData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/app/raise-ticket`);
      setContactUsData(response.data.tickets);
      // setBannerData(response.data.tickets)
      console.log(response.data.tickets);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContactUsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading]);

  // paginating sortedData accordint to currentPage and rowsPerPage
  const [paginatedData, setPaginatedData] = useState([]);

  const [sortedData, setSortedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;


   useEffect(() => {
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;
    setPaginatedData(sortedData.slice(indexOfFirstUser, indexOfLastUser));
    console.log(paginatedData);
  }, [currentPage, sortedData, rowsPerPage, sortingMethod]);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    const totalPage = Math.ceil(sortedData.length / rowsPerPage);
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };


    // FILTERING DATA IN ONE GO
    useEffect(() => {
      // filtering according to search term filter
        const filteredData = contactUsData.filter(item =>
            Object.values(item).some(value => {
              if (value !== null && value !== undefined) {
                return value.toString().toLowerCase().includes(searchTermFilter.toLowerCase());
              }
              return false;
            })
          );
      setFilteredData(filteredData);
    }, [contactUsData, searchTermFilter]);
  
    // sorting searchTermFilteredData according to sortingOn and sortingMethod
    useEffect(() => {
      const tempFilteredData = filteredData;
  
      const asc = (a, b) => {
        if (
          String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
        )
          return 1;
        else if (
          String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
        )
          return -1;
        else return 0;
      };
      const des = (a, b) => {
        if (
          String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
        )
          return 1;
        else if (
          String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
        )
          return -1;
        else return 0;
      };
  
      tempFilteredData.sort(sortingMethod ? asc : des);
      setSortedData(tempFilteredData);
    }, [filteredData, sortingMethod, sortingOn]);



  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-phone me-2" />
                  Tickets List
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item ">Tickets List</li>
                </ol>
              </div>
            </div>

          <form>
          <div className="card mt-5 ">
              <div className="card-header d-flex">
              <input
                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for User Tickets List"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                />
                <button
                  type="submit"
                  className="btn btn-success"
                  // onClick={handleUpdateAboutUs}
                >
                  Search
                </button>
              </div>
              <div style={{overflowX: 'auto'}}>
                <table className='Query_detalis'>
                    <tr>
                      <th scope="col">#</th>
                      <th >First Name</th>
                      <th>Email ID</th>
                      <th>Phone no.</th>
                      <th>Issue</th>
                      <th>Description</th>
                    </tr>

                    {paginatedData.map((ticketraise,index)=>{
                      return(
                        <tr key={index}>
                          <th scope="row">
                              {currentPage * rowsPerPage -
                                rowsPerPage +
                                index +
                                1}
                            </th>
                        <td>{ticketraise?.raised_by?.name}</td>
                        <td>{ticketraise?.raised_by?.email}</td>
                        <td>{ticketraise?.raised_by?.phone_no}</td>
                        <td>{ticketraise?.issue_type}</td>
                        <td className='Query_detlis'>{ticketraise?.description}</td>
                      </tr>

                      )
                    })}
                   
                </table>
              </div>
              {/* <div className="card-footer clearfix d-flex justify-content-end text-dark">
                {contactUsData.updatedAt && (
                  <>Last Modified : {JsDateToString(contactUsData.updatedAt)}</>
                )}
              </div> */}
              <div className="card-footer clearfix d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={prevPage}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  style={{ width: '100px', textAlign: 'center' }}
                  value={`${currentPage}/${
                    Math.ceil(contactUsData.length / rowsPerPage) || 1
                  }`}
                  readOnly={true}
                  placeholder='1/2'
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={nextPage}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
