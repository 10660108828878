import React, { useEffect, useState } from 'react'
import getAxiosInstance from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { useParams } from 'react-router-dom';

function ScholarshipCertificateModel({fetchCertificate}) {
    const { scholarshipUserId } = useParams();
    const { setIsLoading } = useStore();
let initaleData = {
    of_user:scholarshipUserId,
    title:"",
    name:"",
    date:'',
    description:""
}
const [inpvalue,setinpvalue] = useState(initaleData)
console.log(inpvalue)
    function changeinpvalue(e){
        console.log(e.target.value);
        setinpvalue({...inpvalue,[e.target.name] : e.target.value})
    


    }

    async function CertificateEdit(e){
        e.preventDefault();
        setIsLoading(true)
        try{
            const response = await getAxiosInstance().post(`/api/v1/scholarship/Certificate`,inpvalue)
            console.log(response)
            setIsLoading(false)
            fetchCertificate()
            setinpvalue(initaleData)
            

        }
        catch(error){
            console.log(error);
            setIsLoading(false)
        }

    }


   

    return (
        <>
            {/* <!-- Button trigger modal --> */}
            <button
                type="button"
                className="btn btn-dark ms-2 d-flex align-items-center mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
            >

                <i className="fa fa-cog mr-2" aria-hidden="true" />
                Edit
            </button>

            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <form class="modal-content" onSubmit={CertificateEdit}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Edit Certificate</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <label htmlFor="certificate_title" className="form-label mt-2">
                                Title
                            </label>
                            <input
                                required
                                onChange={changeinpvalue}
                                type="text"
                                name='title'
                                className="form-control"
                                id="certificate_title"
                            />
                            <label htmlFor="certificate_name" className="form-label mt-2">
                                Name
                            </label>
                            <input
                                required
                                onChange={changeinpvalue}
                                type="text"
                                name='name'
                                className="form-control"
                                id="certificate_name"
                            />
                            <label htmlFor="certificate_time" className="form-label mt-2">
                                Date
                            </label>
                            <input
                                required
                                onChange={changeinpvalue}
                                type="datetime-local"
                                name='date'
                                className="form-control"
                                id="certificate_time"
                            />
                            <label htmlFor="certificate_description" className="form-label mt-2">
                                Description

                            </label>
                            <textarea
                                required
                                onChange={changeinpvalue}
                                type="text"
                                name='description'
                                className="form-control"
                                id="certificate_description"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ScholarshipCertificateModel