import React, { useState, useEffect, useRef } from 'react';
import { Link,useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const FAQ = () => {
  const { setIsLoading } = useStore();
  const { faqId } = useParams();
  const [FAQData, setFAQData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('question');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPage = 20;
console.log(faqId);
  // getting FAQ data from database
  const fetchFAQData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/app/faqs`);
      setFAQData(response.data.faqs);
      console.log(response.data.faqs);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFAQData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading,faqId]);

  // FILTERING DATA IN ONE GO
  useEffect(() => {
    // filtering according to search term filter
    const tempCourseCategoriesData = FAQData;
    const tempSearchTermFilterData = tempCourseCategoriesData.filter(
      (category) => {
        if (searchTermFilter === '') {
          return true;
        } else {
          if (
            category['question']
              .toLowerCase()
              .includes(searchTermFilter.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    );

    setFilteredData(tempSearchTermFilterData);
  }, [FAQData, searchTermFilter]);

  // sorting searchTermFilteredData according to sortingOn and sortingMethod
  useEffect(() => {
    const tempFilteredData = filteredData;

    const asc = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };
    const des = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };

    tempFilteredData.sort(sortingMethod ? asc : des);
    setSortedData(tempFilteredData);
  }, [filteredData, sortingMethod, sortingOn]);

  // paginating sortedData accordint to currentPage and rowsPerPage
  useEffect(() => {
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;
    setPaginatedData(sortedData.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, sortedData, rowsPerPage, sortingMethod]);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    const totalPage = Math.ceil(sortedData.length / rowsPerPage);
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-question-circle me-2" />
                  FAQ
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item ">FAQ</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5">
              <div className="card-header d-flex">
                <input
                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for FAQ question"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                />
                <ManageWelcomeScreenModal fetchFAQData={fetchFAQData} />
              </div>
              <div className="card-body" style={{ overflow: 'auto' }}>
                <table
                  className="table table-hover"
                  style={{ minWidth: '840px' }}
                >
                  <thead className="table-light">
                    <tr>
                      <th scope="col">#</th>
                      <th
                        scope="col"
                        className="w-100"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSortingMethod(!sortingMethod);
                          setSortingOn('question');
                        }}
                      >
                        Question
                        <i className="ms-2 fa fa-sort" aria-hidden="true" />
                      </th>
                      <th scope="col">Manage</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    <TableContent
                      fetchFAQData={fetchFAQData}
                      paginatedData={paginatedData}
                      currentPage={currentPage}
                      rowsPerPage={rowsPerPage}
                    />
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={prevPage}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  style={{ width: '100px', textAlign: 'center' }}
                  value={`${currentPage}/${
                    Math.ceil(FAQData.length / rowsPerPage) || 1
                  }`}
                  readOnly={true}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={nextPage}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;

const TableContent = ({
  fetchFAQData,
  paginatedData,
  currentPage,
  rowsPerPage,
}) => {
  return (
    <>
      {paginatedData.length === 0 ? (
        <tr>
          <td colSpan="8" className="text-center">
            No data
          </td>
        </tr>
      ) : (
        paginatedData.map((data, index) => {
          return (
            <>
            <tr key={data._id}>
              <th scope="row">
                {currentPage * rowsPerPage - rowsPerPage + index + 1}
              </th>
              <td>
                <div style={{ fontSize: '80%', color: 'red' }}
                
                dangerouslySetInnerHTML={{
                  __html: data.question?.replaceAll('&lt;', '<'),
                }}
                >
                  
                </div>
                <div
                dangerouslySetInnerHTML={{
                  __html: data.answer?.replaceAll('&lt;', '<'),
                }}
                ></div>
              </td>
             
              <td>
                <ManageWelcomeScreenModal
                  data={data}
                  fetchFAQData={fetchFAQData}
                />
              </td>
            </tr>
             
            </>
          )
        })
      )}
    </>
  );
};

const ManageWelcomeScreenModal = ({ data, fetchFAQData }) => {
  const CloseButton = useRef();
  const { setIsLoading } = useStore();
  const initialLocalData = {
    question: '',
    answer: '',
    faq_title:'',
  };

  const [localData, setLocalData] = useState(initialLocalData);

  useEffect(() => {
    if (!data) return;

    setLocalData(data);
  }, [data]);

  const handleAddFAQ = async () => {
    try {
      setIsLoading(true);
      await axios().post(`/api/v1/app/faq`, localData);
      Toast.fire({
        icon: 'success',
        title: 'FAQ added',
      });
      setLocalData(initialLocalData);
      fetchFAQData();
      CloseButton.current.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };
  // const handleAddFAQ = async (event) => {
  //   event.preventDefault();

  //   try{
  //     const config = {
  //       method: 'POST',
  //       headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(localData)
  //   }

  //   const response = await axios().post(`/api/v1/app/faq`, config);
   
  //   console.log(response);
  // //   if (response.ok) {

  // //     return response
      
  // // } else {
  // //     console.log("not send");
  // // }
  //   }catch(error){
    
  //     console.log(error)}

    
  // };


  const handleUpdateFAQ = async () => {
    try {
      setIsLoading(true);
      await axios().patch(`/api/v1/app/faq/${data._id}`, localData);
      Toast.fire({
        icon: 'success',
        title: 'FAQ updated',
      });
      fetchFAQData();
      CloseButton.current.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  const handleDeleteFAQ = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h6>This FAQ will get permanently deleted</h6>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#D14343',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          await axios().delete(`/api/v1/app/faq/${data._id}`);
          Toast.fire({
            icon: 'success',
            title: 'FAQ deleted',
          });
          setTimeout(function () {
            fetchFAQData();
          }, 500);
          CloseButton.current.click();
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          Toast.fire({
            icon: 'error',
            title: error.response.data
              ? error.response.data.msg
              : error.message,
          });
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-dark ms-2 d-flex align-items-center"
        data-toggle="modal"
        data-target={data ? `#${data._id}` : '#add-faq-modal'}
      >
        {data ? (
          <i className="fa fa-cog" aria-hidden="true" />
        ) : (
          <>          
            <i className="fa fa-plus me-1" aria-hidden="true" /> FAQ
          </>
        )}
      </button>    
      <div
        className="modal fade"
        id={data ? data._id : 'add-faq-modal'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleAddFAQ}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {data ? <>Manage FAQ</> : <>Add FAQ</>}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseButton}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
           
           
           <label htmlFor="faq_title" className="form-label mt-2">
                FAQ Heading
              </label>
              
           
            
                <input
                type='text'
                className="form-control"
                placeholder='Enter faq type'
                id="faq_title"
                value={localData.faq_title?.replaceAll('&lt;', '<')}
                onChange={(e) => {
                  setLocalData({
                    ...localData,
                    faq_title: e.target.value,
                  });
                }}
                
              >
                
              </input>
             
            

              <label htmlFor="question" className="form-label mt-2">
                FAQ Question
              </label>
              <input
              required
              placeholder='Enter faq question'
                type="text"
                className="form-control"
                id="question"
                value={localData.question?.replaceAll('&lt;', '<')}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    question: e.target.value,
                  })
                }
              />

              <label htmlFor="answer" className="form-label mt-2">
                FAQ Answer
              </label>
              <textarea
              required
              placeholder='Enter faq answer'
                type="text"
                className="form-control"
                id="answer"
                value={localData.answer?.replaceAll('&lt;', '<')}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    answer: e.target.value,
                  })
                }
              />
              
            </div>
            <div className="modal-footer">
              {data ? (
                <>
                  {/* Manage */}
                  <button
                    type="button"
                    className="btn btn-danger me-auto"
                    onClick={handleDeleteFAQ}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUpdateFAQ}
                  >
                    Save changes
                  </button>
                </>
              ) : (
                <>
                  {/* Add New */}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    // onClick={handleAddFAQ}
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
          </form>
        </div>
      </div>

   
    </>
  );
};
