import React, { useState, useEffect } from 'react';
import { useStore } from '../../Contexts/StoreContext';
import axios from '../../Utils/axios';
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function Dashboard() {
  const { setIsLoading, accessLevel } = useStore();
  const [dashboardData, setDashboardData] = useState({
    user_account_count: 0,
    admin_account_count: 0,
    teacher_account_count: 0,
    course_count: 0,
    sales_count: 0,
    fee_received: 0,
  });

  // getting dashboard data from database
  const fetchHelpData = async () => {
    try {
      setIsLoading(true);
      const response = await axios().get(`/api/v1/app/dashboard`);
      setDashboardData(response.data.dashboard_data);
      console.log(response.data.help);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
      setIsLoading(false);
    }
  };

  console.log(dashboardData);

  useEffect(() => {
    fetchHelpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item ">Dashboard</li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-md-12">
                <div className="card">
                  <div className="row p-2">
                    {accessLevel === 'admin' && (
                      <>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="info-box mb-3">
                            <span className="info-box-icon bg-danger elevation-1">
                              <i className="fas fa-users" />
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Admin accounts
                              </span>
                              <span className="info-box-number">
                                {dashboardData.admin_account_count}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="info-box mb-3">
                        <span className="info-box-icon bg-warning elevation-1">
                          <i className="fas fa-users" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">
                            Teacher accounts
                          </span>
                          <span className="info-box-number">
                            {dashboardData.teacher_account_count}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="info-box mb-3">
                        <span className="info-box-icon bg-info elevation-1">
                          <i className="fas fa-users" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">User accounts</span>
                          <span className="info-box-number">
                            {dashboardData.user_account_count}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="info-box mb-3">
                        <span className="info-box-icon bg-success elevation-1">
                          <i className=" fa fa-book" />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text">Total Courses</span>
                          <span className="info-box-number">
                            {dashboardData.course_count}
                          </span>
                        </div>
                      </div>
                    </div>
                    {accessLevel === 'admin' && (
                      <>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="info-box mb-3">
                            <span className="info-box-icon bg-success elevation-1">
                              <i className="fa fa-shopping-cart" />
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-text">Total Sales</span>
                              <span className="info-box-number">
                                {dashboardData.sales_count}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="info-box mb-3">
                            <span className="info-box-icon bg-danger elevation-1">
                              <i className="fa fa-usd" />
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-text">
                                Total Fee Received
                              </span>
                              <span className="info-box-number">
                                {dashboardData.fee_received}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
