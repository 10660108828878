import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../../Utils/axios';
import { useStore } from '../../../Contexts/StoreContext';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../../Utils/firebaseConfig';
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
} from 'firebase/auth';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function SignIn() {
  const navigate = useNavigate();

  const googleAuthProvider = new GoogleAuthProvider();
  const facebookAuthProvider = new FacebookAuthProvider();

  const { setIsLoading } = useStore();
  const [userData, setUserData] = useState({
    email: '',
    phone_no: '',
    country_code: '',
    password: '',
  });
  const [emailLogin, setEmailLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const signUserIn = async (e) => {
    e.preventDefault();
    if (emailLogin && userData.email === '')
      return Toast.fire({ icon: 'error', title: 'Email required' });
    if (!emailLogin && userData.phone_no === '')
      return Toast.fire({ icon: 'error', title: 'Phone No required' });
    if (userData.password === '')
      return Toast.fire({ icon: 'error', title: 'Password required' });

    const params = {
      password: userData.password,
    };

    if (emailLogin) {
      params.email = userData.email;
    } else {
      params.phone_no = Number(userData.phone_no);
      params.country_code = Number(userData.country_code);
    }

    setIsLoading(true);

    try {
      const res = await axios().post('/api/v1/auth/login', params);
      Toast.fire({
        icon: 'success',
        title: 'Logged In',
      });
      setIsLoading(false);

      if (!(res.data.role === 'admin' || res.data.role === 'teacher')) {
        Toast.fire({
          icon: 'error',
          title: 'User account not allowed',
        });
        return;
      }

      localStorage.setItem('access_token', res.data.token);
      localStorage.setItem('access_level', res.data.role);

      window.location.reload();
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: 'error',
        title: err.response.data?.msg || err.message,
      });

      if (err.response.data) {
        if (
          err.response.data.msg === 'Email not verified' ||
          err.response.data.msg === 'Phone number not verified'
        ) {
          navigate('/verify-account');
        }
      }
      setIsLoading(false);
    }
  };

  const handleLoginWithSocialAccount = async (e, provider) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let res;
      if (provider === 'google') {
        res = await signInWithPopup(auth, googleAuthProvider);
        await signOut(auth);

        const response = await axios().post(
          process.env.REACT_APP_BASE_URL + '/api/v1/auth/social/login',
          {
            provider: 'google',
            google_login_uid: res.user.uid,
          }
        );

        console.log(response);

        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('access_level', response.data.role);
        setIsLoading(false);

        window.location.reload();
      } else if (provider === 'facebook') {
        res = await signInWithPopup(auth, facebookAuthProvider);
        await signOut(auth);

        const response = await axios().post(
          process.env.REACT_APP_BASE_URL + '/api/v1/auth/social/login',
          {
            provider: 'facebook',
            facebook_login_uid: res.user.uid,
          }
        );

        console.log(response);

        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('access_level', response.data.role);
        setIsLoading(false);

        window.location.reload();
      } else {
        Toast.fire({ icon: 'success', title: 'Please provide valid provider' });
        setIsLoading(false);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });

      if (error.response.data) {
        if (
          error.response.data.msg === 'Email not verified' ||
          error.response.data.msg === 'Phone number not verified'
        ) {
          navigate('/verify-account');
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <form className="form-horizontal">
        <h2 style={{ color: '#775DA8' }}>Login | Admin</h2>
        <h6>Please enter your log in details</h6>
        <h6>Enter your mobile number or Email to continue.</h6>
        <span>Login with : </span>
        <div className="form-check form-switch d-inline-block mt-5 mb-3">
          <span
            className="form-check-span pl-0"
            htmlFor="emailPhoneSwitch"
            style={{
              color: !emailLogin && 'grey',
              fontWeight: emailLogin && 'bold',
            }}
          >
            Email
          </span>
          <input
            className="form-check-input ml-2 d-inline-block"
            type="checkbox"
            role="switch"
            id="emailPhoneSwitch"
            onChange={() => setEmailLogin((prev) => !prev)}
          />
          <span
            className="form-check-span ml-5"
            htmlFor="emailPhoneSwitch"
            style={{
              color: emailLogin && 'grey',
              fontWeight: !emailLogin && 'bold',
            }}
          >
            Phone No
          </span>
        </div>

        <div className="form-group row">
          {emailLogin ? (
            <>
              <label htmlFor="emailField" className="form-label">
                Email
              </label>
              <div className="">
                <input
                  type="email"
                  className="form-control"
                  id="emailField"
                  placeholder="abc@email.com"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </div>
            </>
          ) : (
            <>
              <label htmlFor="phoneNoField" className="form-label">
                Phone No
              </label>
              <div className="d-flex">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    +
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="countryCodeField"
                    style={{ maxWidth: '50px' }}
                    placeholder="XX"
                    value={userData.country_code}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        country_code: e.target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNoField"
                    placeholder="XXXXXXXXXX"
                    value={userData.phone_no}
                    onChange={(e) =>
                      setUserData({ ...userData, phone_no: e.target.value })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="form-group row">
          <label htmlFor="passwordField" className="form-label">
            Password
          </label>
          <div className="">
            <div className="input-group mb-1">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control  border-right-0"
                id="passwordField"
                placeholder="Password"
                value={userData.password}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
              <span
                className="input-group-text bg-white"
                id="basic-addon2"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <>
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  </>
                ) : (
                  <>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-success w-100"
          onClick={(e) => signUserIn(e)}
        >
          Sign in
        </button>
        {/* <button
          type="submit"
          className="btn btn-outline-dark w-100 my-2"
          onClick={(e) => handleLoginWithSocialAccount(e, 'google')}
        >
          <i className="fa fa-google me-2" aria-hidden="true"></i>
          Google Login
        </button>
        <button
          type="submit"
          className="btn btn-outline-dark w-100"
          onClick={(e) => handleLoginWithSocialAccount(e, 'facebook')}
        >
          <i class="fa fa-facebook me-2" aria-hidden="true"></i>
          Facebook Login
        </button> */}
        <div className="my-2 text-center ">
          <Link to="/forget-password">Forget Password?</Link>
        </div>
        <hr style={{ width: '80%', margin: '0 auto', background: 'black' }} />
        {/* <Link to="/sign-up">
          <button className="btn btn-primary w-100 mt-2 ">
            Create new Account
          </button>
        </Link> */}
      </form>
    </>
  );
}
