import React, { Fragment, useContext, useRef, useState } from 'react'
import Logo from '../../assets/logo.png'
import Signeture from '../../assets/signetur.png'
// import UseAxios from '../util/UseAxios'
// import { userContext } from '../Context/UserContextProvider'

function ViewCertificateMode({pdfLink,CertificateID}) {

    // const instance = UseAxios()
    // const { showetest, setShoweTest, mode } = useContext(userContext)
    // let [responseReport, setResponseReport] = React.useState([]);
    // let [showetable, setShowetable] = React.useState(5);

    // let filterdata = responseReport?.find(item => item?.reportId === showetest)
    // console.log(filterdata?.topicScore)

    return (
      <>
        <button
          type="button"
          className="btn btn-dark ms-2 d-flex align-items-center mt-2"
          data-toggle="modal"
          data-target={`#exampleModalCenter_${CertificateID}`}
        >
          <i className="fa fa-eye mr-2" aria-hidden="true" />
          View certificate
        </button>

        {/* <!-- Modal-- > */}
        <div
          class="modal fade"
          id={`exampleModalCenter_${CertificateID}`}
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered model_diloage_div_certificate "
            role="document"
          >
            {/* <div class="modal-content model_diloage_div_certificate_div">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">View Certificate</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                   
                        <div className='display_flex_class'>
                            <section className='Scholarship_Certificate_border'>
                                <section className="">
                                    <a href='' >
                                        <img className="logo" src={Logo} alt="" />
                                    </a>
                                </section>
                                <div className='Scholarship_Certificate'>
                                    <h1>Certificate</h1>
                                    <span className='Name_of_student'>Jone Sing</span>
                                    <p className='Certificate_contant'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s wit</p>
                                    <div className='FounderCEO'>
                                        <img src={Signeture} alt="..." />
                                        <small>Founder&CEO,Navya</small>
                                    </div>
                                    <div className='Certificate_date_no'>
                                        <span>
                                            Date of certification: <small>2023-08-01</small>
                                        </span>
                                        <span>
                                            Certificate no. : <small>1BE4B79D-597D-0C9E-A2E7</small>
                                        </span>
                                    </div>
                                    <p className='certificate_visit'>For certificate authentication, please visit <a href="https://navyaedu.com/certificate/certificate_no">https://navyaedu.com/certificate/certificate_no</a></p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                    <button
                                        type="button"
                                        className="btn btn-dark ms-2 d-flex align-items-center"
                                        data-toggle="modal"
                                    >

                                        <i className="fa fa-share mr-2" aria-hidden="true" />
                                        Share
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark ms-2 d-flex align-items-center"
                                        data-toggle="modal"
                                    >

                                        <i className="fa fa-copy mr-2" aria-hidden="true" />
                                        Copy
                                    </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div> */}
                    <div class="modal-content model_diloage_div_certificate_div">
                    <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">View Certificate</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
            <iframe
              src={pdfLink}
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
              title='PDF viwer'
            ></iframe>
            </div>
          </div>
        </div>
      </>
    );
}

export default ViewCertificateMode