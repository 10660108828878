import React, { useRef, useState } from 'react'
import axios from '../../Utils/axios';
import Swal from 'sweetalert2';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
function ScholarShipModal({data,fetchScholarshipData}) {
    console.log(fetchScholarshipData)
    // console.log(data)


    const CloseButton = useRef();
    const initialLocalData = {
        scholarship_category_name: data?.scholarship_category_name || "",
        // image: "",
      };
      

const [localData,setLocaldata] =useState(initialLocalData)

    async function scholarshipSubmit(e){
      e.preventDefault()
            try {
            //   setIsLoading(true);
              const res = await axios().post(`/api/v1/scholarship/scholarship-category`, localData);
              console.log(res)
              Toast.fire({
                icon: 'success',
                title: res.data.message || '"Scholarship created successfully"',
              });
            //   handleImageUpload(res.data.category._id);
            //   setLocalData(initialLocalData);
            CloseButton.current.click();
            // window.location.reload();
              setTimeout(function () {
                fetchScholarshipData();
              }, 500);
            //   setIsLoading(false);
            setLocaldata(initialLocalData)
            } catch (error) {
              console.log(error);
            //   Toast.fire({
            //     icon: 'error',
            //     title: error.response.data ? error.response.data.msg : error.message,
            //   });
            //   setIsLoading(false);
            }



         
    }
    async function updatescholarshipData(scholarshipid){

            try {
            //   setIsLoading(true);
              const res = await axios().patch(`/api/v1/scholarship/scholarship-category/${scholarshipid}`, localData);
              console.log(res)
              Toast.fire({
                icon: 'success',
                title: res.data.message || '"Scholarship Updated successfully"',
              });
            //   handleImageUpload(res.data.category._id);
            //   setLocalData(initialLocalData);
            CloseButton.current.click();
            // window.location.reload();
            setTimeout(function () {
                  fetchScholarshipData();
              }, 500);
            //   setIsLoading(false);
            setLocaldata(initialLocalData)
            } catch (error) {
              console.log(error);
            //   Toast.fire({
            //     icon: 'error',
            //     title: error.response.data ? error.response.data.msg : error.message,
            //   });
            //   setIsLoading(false);
            }



         
    }
    async function handleDeleteQuiz(scholarshipid){
      
              try {
              //   setIsLoading(true);
                const res = await axios().delete(`/api/v1/scholarship/scholarship-category/${scholarshipid}`);
                console.log(res)
                Toast.fire({
                  icon: 'success',
                  title: res.data.message || '"Scholarship deleted successfully"',
                });
              //   handleImageUpload(res.data.category._id);
              //   setLocalData(initialLocalData);
              CloseButton.current.click();
              setTimeout(function () {
                fetchScholarshipData();
            }, 500);
        
        
              } catch (error) {
                console.log(error);
              //   Toast.fire({
              //     icon: 'error',
              //     title: error.response.data ? error.response.data.msg : error.message,
              //   });
              //   setIsLoading(false);
              }
      
      
      
           
      }

    function scholarshipinp(e){
        // setLocaldata({...initialLocalData,scholarship_category_name:e.target.value})
        setLocaldata((pre)=>({...pre ,scholarship_category_name:e.target.value}))

    }
    console.log(localData)
    return (
        <div>
            
                <button
                    type="button"
                    className="btn btn-dark ms-2 d-flex align-items-center"
                    data-toggle="modal"
                    data-target={data ? `#${data._id}` : '#add-course-modal'}
                >
                    {data ? (
                        <i className="fa fa-cog" aria-hidden="true" />
                    ) : (
                        <>
                            <i className="fa fa-plus me-1" aria-hidden="true" /> Scholarship
                        </>
                    )}
                </button>
                <div
                    className="modal fade"
                    id={data ? data._id : 'add-course-modal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <form
                         onSubmit={scholarshipSubmit}
                        >
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        {data ? <>Manage ScholarShip</> : <>Add ScholarShip</>}
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        ref={CloseButton}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {data ? (
                                        <>
                                            <div>
                                            
                                                {/* <div style={{ width: '100%', height: '200px' }}>
                                                    <img
                                                        src=""
                                                        alt="course"
                                                        style={{
                                                            width: '100%',
                                                            height: ' 100%',
                                                            objectFit: 'contain',
                                                            overflow: 'hidden',
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="image">Course Cover Image</label>
                                                <input
                                                    required
                                                    id="image"
                                                    type="file"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    className="form-control  w-100 mb-2"
                                                />

                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    Update
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger ms-2"
                                                >
                                                    Remove
                                                </button> */}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="bg-info-subtle d-flex align-items-center px-2 py-1 rounded-2">
                                                <i className="fa fa-info-circle me-1" aria-hidden="true" />
                                                Upload cover image after adding course.
                                            </div>
                                        </>
                                    )}

                                    <label htmlFor="scholarship_category_name" className="form-label mt-2">
                                    Scholarship Title
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="scholarship_category_name"
                                        name='scholarship_category_name'
                                        onChange={scholarshipinp}
                                        value={localData.scholarship_category_name}
                                    />
                                    {/* <label htmlFor="course_subtitle" className="form-label mt-2">
                                    Scholarship Subtitle
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="course_subtitle"
                                    /> */}
                                    {/* <label htmlFor="course_subtitle" className="form-label mt-2">
                                    Scholarship Date & Time
                                    </label>
                                    <input
                                        required
                                        type="datetime-local"
                                        className="form-control"
                                        id="birthdaytime"
                                        name='birthdaytime'
                                    /> */}
                                    
                                    {/* <label htmlFor="course_description" className="form-label mt-2">
                                    Scholarship Description

                                    </label>
                                    <textarea
                                        required
                                        type="text"
                                        className="form-control"
                                        id="course_description"
                                    /> */}
                                </div>
                                <div className="modal-footer">
                                    {data ? (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-danger me-auto"
                                                onClick={()=>handleDeleteQuiz(data._id)}
                                            >
                                                Delete
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={()=>updatescholarshipData(data._id)}
                                            >
                                                Save changes
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {/* Add New */}
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                Add
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            

        </div>
    )
}

export default ScholarShipModal;