import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useStore } from '../../Contexts/StoreContext';
import { useNavigate, useParams } from 'react-router-dom';
import getAxiosInstance from '../../Utils/axios';
import ScholarShipTestModel from './ScholarShipTestModel';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
const Quiz = () => {
    const {scholarshipquizId} = useParams()
const data = {
    _id: 1,
    "scholarship_quiz_name": "Sample Quiz",
    "scholarship_quiz_description": "Description of the sample quiz",
    "scholarship_quiz_terms_and_condition": "Terms and conditions of the quiz",
    "show_questions_randomly": true,
    "can_switch_sections": true,
    "scholarship_quiz_duration": 60, 
    "break_between_sections": 10,
    "max_attempts": 3 
  }
//   /api/v1/scholarship/quiz/663c9f4c9ce0af8989313663
const navigate = useNavigate()

const instance =getAxiosInstance()

const [scholarship, setScholarship] = useState([])
const [searchTermFilter, setSearchTermFilter] = useState('');
const [paginatedData, setPaginatedData] = useState([]);


   const fetchScholarshipData = async()=>{
    try {
    //   setIsLoading(true);
      const quizResponse = await instance.get(`/api/v1/scholarship/quiz/${scholarshipquizId}`);
      setScholarship(quizResponse.data.quizzes);
      console.log(quizResponse.data);
    //   setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: error.response.data ? error.response.data.msg : error.message,
      });
    //   setIsLoading(false);
    }
  };

  useEffect(() => {
     fetchScholarshipData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(()=>{
    const filteredData = scholarship.filter(item =>
        Object.values(item).some(value => {
          if (value !== null && value !== undefined) {
            return value.toString().toLowerCase().includes(searchTermFilter.toLowerCase());
          }
          return false;
        })
      );
      setPaginatedData(filteredData)
},[searchTermFilter,scholarship])

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">
                                    <i className="nav-icon fa fa-pie-chart me-2" />
                                    ScholarShip Quiz
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/scholarShip"> ScholarShip</Link>
                                    </li>
                                    <li className="breadcrumb-item ">Quiz</li>
                                </ol>
                            </div>
                        </div>

                        <div className="card mt-5">
                            <div className="card-header d-flex">
                                <input
                                    type="text"
                                    className="form-control flex-grow-1"
                                    placeholder="Search for course Test"
                                    autoFocus={true}
                                    value={searchTermFilter}
                                    onChange={(e) => {
                                      setSearchTermFilter(e.target.value);
                                    }}

                                />
                                <ScholarShipTestModel />
                            </div>
                            <div className="card-body" style={{ overflow: 'auto' }}>
                                <table
                                    className="table table-hover"
                                    style={{ minWidth: '840px' }}
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                className="w-50"
                                                style={{ cursor: 'pointer' }}

                                            >
                                                Name
                                                <i className="ms-2 fa fa-sort" aria-hidden="true" />
                                            </th>
                                            
                                            <th
                                                scope="col"
                                                style={{ cursor: 'pointer' }}

                                            >
                                                <div className="d-flex">
                                                    Duration
                                                    <i className="ms-2 fa fa-sort" aria-hidden="true" />
                                                </div>
                                            </th>

                                            <th scope="col">Test Users</th>
                                            <th scope="col">Question</th>
                                            <th scope="col">Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                       {
                                        paginatedData.map((Quizdata,index)=>{
                                            return(
                                                <tr>
                                                <th scope="row">
                                                    {index+1}
                                                </th>
                                                <td>{Quizdata?.scholarship_quiz_name || ""}</td>
                                                <td>{Quizdata?.scholarship_quiz_duration || 0}</td>
                                                <td>
                                                        <button
                                                            onClick={() => navigate(`/scholarshiptestUser/${scholarshipquizId}/${Quizdata._id}`)}
                                                            type="button"
                                                            className="btn btn-info py-2 d-flex align-items-center"
                                                        >
                                                            <i className="nav-icon fas fa-users me-1 py-1" />    
                                                           
                                                        </button>
                                                    </td>
                                                
                                                
                                                <td>
                                                    <button
                                                    onClick={() =>navigate(`/scholarship/${scholarshipquizId}/${Quizdata._id}`)}
                                                        type="button"
                                                        className="btn btn-info py-2 d-flex align-items-center"
                                                    >
                                                        <i
                                                            className="fa fa-pencil-square-o me-1"
                                                            aria-hidden="true"
                                                        />
                                                        {Quizdata?.scholarship_quiz_sections?.length || 0}
                                                    </button>
                                                </td>
                                                <td>
                                                    <ScholarShipTestModel data={Quizdata} />
                                                </td>
                                            </tr>

                                            )
                                        })
                                       }
                                       
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary me-2"
                                //   onClick={prevPage}
                                >
                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                </button>
                                <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    style={{ width: '100px', textAlign: 'center' }}
                                    //   value={`${currentPage}/${Math.ceil(quizData.length / rowsPerPage) || 1
                                    //     }`}
                                    readOnly={true}
                                />
                                <button
                                    type="button"
                                    className="btn btn-outline-primary ms-2"
                                //   onClick={nextPage}
                                >
                                    <i className="fa fa-arrow-right" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Quiz;

