import React, { useState, useEffect, useRef } from 'react';

function QuestionModel({data}) {

    console.log(data)

    const CloseButton = useRef();
  return (
    <>
      <button
        type="button"
        className="btn btn-dark ms-2 d-flex align-items-center"
        data-toggle="modal"
        id={data ? `open-${data._id}` : 'open-add-quiz-modal'}
        data-target={data ? `#${data._id}` : '#add-quiz-section-question-modal'}
      >
        {data ? (
          <i className="fa fa-cog" aria-hidden="true" />
        ) : (
          <>
            <i className="fa fa-plus me-1" aria-hidden="true" /> Question
          </>
        )}
      </button>
      <div
        className="modal fade"
        id={data ? data._id : 'add-quiz-section-question-modal'}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
            <form
            //  onSubmit={handleAddQuizSectionQuestion}

             >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">

            
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {data ? <>Manage Question</> : <>Add Question</>}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseButton}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <nav className="nav nav-pills flex-column flex-sm-row mb-2 bg-body-secondary">
                <button
                type='button'
                  className={`flex-sm-fill text-sm-center nav-link`}
                >
                  English
                </button>
                <button
                type='button'
                  className={`flex-sm-fill text-sm-center nav-link`}
                >
                  Hindi
                </button>
              </nav>
              {/* {navTab === 1 ? ( */}
                <>
                  <label htmlFor="question_title" className="form-label mt-2">
                    Question (English)
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="question_title"
                    
                  />
                </>
              {/* ) : ( */}
                <>
                  <label
                    htmlFor="question_title_hindi"
                    className="form-label mt-2"
                  >
                    Question (Hindi)
                  </label>
                  <input
                  required
                    type="text"
                    className="form-control"
                    id="question_title_hindi"
                    
                  />
                </>
              {/* )} */}

              {data ? (
                <>
                  <label htmlFor="question_image" className="form-label mt-2">
                    Question Image
                  </label>
                  <div className="d-flex gap-2">
                    {/* {data && localData.question_image && ( */}
                      <>
                        <div className="d-flex">
                          <button
                            type='button'
                            className="btn btn-info rounded-0"
                          >
                            <div className="d-flex align-items-center">
                              Image
                              <i
                                className="fa fa-file-image-o ms-1"
                                aria-hidden="true"
                              />
                            </div>
                          </button>
                          <button
                          type='button'
                            className="btn btn-danger rounded-0"
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </button>
                        </div>
                      </>
                    {/* )} */}
                    <input
                      type="file"
                      readOnly
                      id="question_image"
                      accept="image/*"
                      className="form-control"
                    />
                    <button
                    type='button'
                      className="btn btn-success ms-auto"
                    >
                      <i className="fa fa-cloud-upload" aria-hidden="true" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-info-subtle rounded-2 p-2 mt-2">
                    Upload Question Image after saving Question
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="question_type" className="form-label mt-2">
                    Type
                  </label>
                  <select
                    required
                    className="form-select w-100"
                    id="question_type"
                    
                  >
                    <option value="scq">Single Choice</option>
                    <option value="mcq">Multiple Choice</option>
                    <option value="bool">True / False</option>
                    <option value="essay">Essay</option>
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="question_duration"
                    className="form-label mt-2"
                  >
                    Duration (in min)
                  </label>
                  <input
                  required
                    type="number"
                    className="form-control"
                    id="question_duration"
                    
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="question_marks" className="form-label mt-2">
                    Marks
                  </label>
                  <input
                  required
                    type="number"
                    className="form-control"
                    id="question_marks"
                    
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label
                    htmlFor="question_negative_marking"
                    className="form-label mt-2"
                  >
                    Negative Marks
                  </label>
                  <input
                  required
                    type="number"
                    className="form-control"
                    id="question_negative_marking"
                    
                  />
                </div>
                <div className="col-12 col-md-12">
                  <label
                    htmlFor="question_topic"
                    className="form-label mt-2"
                  >
                    Topic
                  </label>
                  <input
                  required
                    type="text"
                    className="form-control"
                    placeholder='Enter Topic name '
                    id="question_topic"
                    
                  />
                </div>
                <div className="col-12 col-md-12">
                  <label
                    htmlFor="question_solution"
                    className="form-label mt-2"
                  >
                    Solution
                  </label>
                  <textarea
                  
                    type="text"
                    className="form-control"
                    placeholder='Enter Question Solution '
                    id="question_solution"
                    
                  />
                </div>
              </div>

              {/* {data && (
                <QuizSectionQuestionOption
                  getOptionId={setOption_Id}
                  question_type={localData.question_type}
                  questionId={data._id}
                />
              )} */}
            </div>

            <div className="modal-footer">
              {data ? (
                <>
                  {/* Manage */}
                  <button
                    type="button"
                    className="btn btn-danger me-auto"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                  >
                    Save changes
                  </button>
                </>
              ) : (
                <>
                  {/* Add New */}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
            </form>
      </div>
    </>
  )
}

export default QuestionModel