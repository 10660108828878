import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { useNavigate, useLocation } from 'react-router-dom';
import ScholarShipModal from './ScholarShipModal';



const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

function Scholarship() {
    const data = {
        _id: 'hiuwqhuewqr',
        name: "abvd"
    }

    const instance = axios()

    const [scholarship, setScholarship] = useState([])
    const [searchTermFilter, setSearchTermFilter] = useState('');
    const [paginatedData, setPaginatedData] = useState([]);


    const fetchScholarshipData = async () => {
        try {
            //   setIsLoading(true);
            const courseResponse = await instance.get(`/api/v1/scholarship/scholarship-category`);
            setScholarship(courseResponse.data.data);
            console.log(courseResponse.data.data);
            //   setIsLoading(false);
        } catch (error) {
            console.log(error);
            Toast.fire({
                icon: 'error',
                title: error.response.data ? error.response.data.msg : error.message,
            });
            //   setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchScholarshipData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(()=>{
        const filteredData = scholarship.filter(item =>
            Object.values(item).some(value => {
              if (value !== null && value !== undefined) {
                return value.toString().toLowerCase().includes(searchTermFilter.toLowerCase());
              }
              return false;
            })
          );
          setPaginatedData(filteredData)
    },[searchTermFilter,scholarship])


    const navigate = useNavigate()

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">
                                    <i className="nav-icon fa fa-book me-2" />
                                    Scholarship
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item ">ScholarShip</li>
                                </ol>
                            </div>
                        </div>

                        <div className="card mt-5">
                            <div className="card-header d-flex">
                                <input
                                    type="text"
                                    className="form-control flex-grow-1"
                                    placeholder="Search for scholarShip"
                                    autoFocus={true}
                                    value={searchTermFilter}
                                    onChange={(e) => {
                                      setSearchTermFilter(e.target.value);
                                    }}

                                />
                                <ScholarShipModal fetchScholarshipData={fetchScholarshipData} />
                                {/* <ManageCourseModal
                //   fetchCoursesData={fetchCoursesData}
                //   courseCategoriesData={courseCategoriesData}
                /> */}
                            </div>
                            <div className="card-body" style={{ overflow: 'auto' }}>
                                <table
                                    className="table table-hover"
                                    style={{ minWidth: '840px' }}
                                >
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                className="w-75"
                                                style={{ cursor: 'pointer' }}

                                            >
                                                Name
                                                <i className="ms-2 fa fa-sort" aria-hidden="true" />
                                            </th>

                                            {/* <th scope="col">Date & Time</th> */}
                                            <th scope="col">Scholarship Quiz</th>
                                            {/* <th scope="col">Test User</th> */}
                                            <th scope="col">Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {paginatedData.map((scholarshipData, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">
                                                        {index + 1}
                                                    </th>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src="https://akm-img-a-in.tosshub.com/indiatoday/images/story/201701/scholarships-647_010217052513.jpg"
                                                                alt="ScholarShip"
                                                                style={{
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    marginRight: '5px',
                                                                }}
                                                            />
                                                            <div>
                                                                <div className="text-bold">
                                                                    {scholarshipData?.scholarship_category_name}
                                                                </div>
                                                                {/* <div>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque esse debitis amet fugit excepturi.</p>
                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <button
                                                            onClick={() => navigate(`/scholarship/${scholarshipData._id}`)}
                                                            type="button"
                                                            className="btn btn-info py-2 d-flex align-items-center"
                                                        >
                                                            <i
                                                                className="fa fa-pencil-square-o me-1"
                                                                aria-hidden="true"
                                                            />
                                                            {scholarshipData?.scholarshipcourses?.length || 0}
                                                        </button>
                                                    </td>
                                                   
                                                    <td>
                                                        <ScholarShipModal data={scholarshipData} fetchScholarshipData={fetchScholarshipData} />

                                                        {/* <button
                                                    type="button"
                                                    className="btn btn-dark ms-2 d-flex align-items-center"
                                                    data-toggle="modal"
                                                >
                                                    <i className="fa fa-cog" aria-hidden="true" />


                                                </button> */}
                                                    </td>

                                                </tr>

                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary me-2"
                                >
                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                </button>
                                <input
                                    required
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    style={{ width: '100px', textAlign: 'center' }}

                                />
                                <button
                                    type="button"
                                    className="btn btn-outline-primary ms-2"

                                >
                                    <i className="fa fa-arrow-right" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scholarship;
