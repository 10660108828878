import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../Utils/axios';
import { useStore } from '../../Contexts/StoreContext';
import { useNavigate, useLocation } from 'react-router-dom';
import ScholarshipCertificateModel from './ScholarshipCertificateModel';
import ViewCertificateMode from './ViewCertificateMode';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});



const ScholarshipTestUserReport = () => {
  
  const { scholarshipcatId,scholarshipquizId,scholarshipUserId } = useParams();
  const { setIsLoading } = useStore();

  const [purchaseData, setPurchasedata] = useState({});
  const [userDeatil, setUserDeatil] = useState({});
  const [usercertificate, setUsercertificate] = useState([]);

  
  console.log(userDeatil);

  // getting help data from database
  const fetchHelpData = async () => {
    try {
      setIsLoading(true);
      const Useresponse = await axios().get(`/api/v1/auth/admin/profile/${scholarshipUserId}`);
      const response = await axios().get(`/api/v1/scholarship/get_scholarship_quiz_report_by_UserID/${scholarshipUserId}`);
      setUserDeatil(Useresponse.data.user)
      console.log(response);
      const datasend = response.data.data[0];
      setPurchasedata(datasend);
      // const datauniqueId = datasend.filter((x) => x.uniqueId === uniqueId && x.planId === planId);
      // setPurchasedetail(datauniqueId);

      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHelpData();
  }, [scholarshipUserId]);



  const showeCertificate = async () => {
    setIsLoading(true);
    try {
      const response = await axios().get(
        `/api/v1/scholarship/Certificate/${scholarshipUserId}`
      );
      console.log(response.data.data)
      setUsercertificate(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    showeCertificate();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fa fa-book me-2" />
                  Scholarship Test User Report
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/scholarship">Scholarship</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`/ScholarshipTestUser/${scholarshipcatId}/${scholarshipquizId}`}
                    >
                      Scholarship Test User
                    </Link>
                  </li>
                  <li className="breadcrumb-item ">User Report</li>
                </ol>
              </div>
            </div>

            <div className="card mt-5">
              <div className="static_data_payment">
                <div className="catagary_by_data_payment">
                  <h3>User Details</h3>
                  <div style={{ padding: "0 20px" }}>
                    <div className="User_payment_details">
                      <p>User Name</p>
                      <span>{userDeatil.name}</span>
                    </div>
                    <div className="User_payment_details">
                      <p>User E-mail</p>
                      <span>{userDeatil.email}</span>
                    </div>
                    <div className="User_payment_details">
                      <p>User Mobile No.</p>
                      <span>{userDeatil.phone_no}</span>
                    </div>
                  </div>
                </div>

                <div className="catagary_by_data_payment">
                  <h3>Scholarship Report Details</h3>

                  <div style={{ padding: "0 20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      <div className="User_payment_details_three">
                        <p>Scholarship Test Name</p>
                        <span>Scholarship test 1</span>
                      </div>
                    </div>
                    <div className="User_payment_details py-1 border-bottom bg-light">
                      <p>Attempted Ques.</p>
                      <span>{purchaseData.attempted_question}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom ">
                      <p>Unattempted Ques.</p>
                      <span>{purchaseData.unAttempted_question}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom bg-light">
                      <p>Not visited</p>
                      <span>{purchaseData.not_visited?.length || 0}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom ">
                      <p>Correct Ans.</p>
                      <span>{purchaseData.correct_answer}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom bg-light">
                      <p>Incorrect Ans.</p>
                      <span>{purchaseData.incorrect_answer}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom ">
                      <p>Obtained Marks</p>
                      <span>{purchaseData.obtained_Marks}</span>
                    </div>
                    <div className="User_payment_details py-1 border-bottom bg-light">
                      <p>Accuracy in %</p>
                      <span>{purchaseData.accuracy} %</span>
                    </div>
                  </div>
                </div>
                <div className="catagary_by_data_payment">
                  <h3>Scholarship Certificate</h3>

                  <div style={{ padding: "0 20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      <div className="d-flex flex-wrap ">
                        <ScholarshipCertificateModel fetchCertificate={showeCertificate} />

                        {usercertificate.map((x) => {
                          return <ViewCertificateMode  pdfLink={x.pdfUrl} CertificateID={x._id} />;
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className=""></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScholarshipTestUserReport;
